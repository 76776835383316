import { createSignal } from "solid-js";

export function ExportImport(props: { onImport(data: any): void; onExport(): any }) {
  // const [data, setData] = createSignal({}); // This is where you'll store the imported data

  const handleFileImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const obj = JSON.parse(event.target.result as any);
        props.onImport?.(obj);
        // setData(obj);
      } catch (error) {
        console.error("Error parsing JSON", error);
      }
    };
    reader.readAsText(file);
  };

  const handleFileExport = () => {
    const data = props.onExport?.();
    if (!data) {
      return;
    }
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "page_template.json";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div class="flex flex-col space">
      <div class="flex flex-row">
        <span>Import Template</span>
        <input type="file" onChange={handleFileImport} accept=".json" />
      </div>
      <div class="flex fex-row ">
        <button onClick={handleFileExport}>Export Template</button>
      </div>
    </div>
  );
}
